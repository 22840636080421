import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-body-style-selector',
  templateUrl: './body-style-selector.component.html',
  styleUrls: ['./body-style-selector.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BodyStyleSelectorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
