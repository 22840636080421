import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Car, CarDataService } from '../car-data.service';


@Component({
  selector: 'app-car-detail-view',
  templateUrl: './car-detail-view.component.html',
  styleUrls: ['./car-detail-view.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CarDetailViewComponent implements OnInit, OnDestroy {

  car: Car;
  private subscription: Subscription;

  constructor(private carDataService: CarDataService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      const id: number = +params['id'];

      // Get the car by id from the data service
      this.carDataService.getCar(id).subscribe((car: Car) => {
        this.car = car;
      });

    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
