import { Component, OnInit, ViewEncapsulation } from '@angular/core';

import { CarDataService } from '../car-data.service';
import { Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';


@Component({
  selector: 'app-search-detail',
  templateUrl: './search-detail.component.html',
  styleUrls: ['./search-detail.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SearchDetailComponent implements OnInit {

  public brands$: Observable<string[]>;

  constructor(private carDataService: CarDataService) {}

  ngOnInit() {
    this.brands$ = this.carDataService.getBrands().pipe(toArray());
  }

}
