export const INVENTORY = {
  cars: [
    {
      brand: 'bmw',
      model: '328i',
      family: '3 Series',
      year: 2011,
      color: 'silver',
      mileage: 52123,
      photos: {
        hero: '../../assets/mock/bmw/328i-silver/hero.png',
        collection: [
          '../../assets/mock/bmw/328i-silver/001.jpg',
          '../../assets/mock/bmw/328i-silver/002.jpg',
          '../../assets/mock/bmw/328i-silver/003.jpg',
          '../../assets/mock/bmw/328i-silver/004.jpg',
          '../../assets/mock/bmw/328i-silver/005.jpg',
          '../../assets/mock/bmw/328i-silver/006.jpg'
        ]
      },
      bodyStyle: 'sedan',
      transmission: 'automatic'
    },
    {
      brand: 'bmw',
      model: '335i',
      family: '3 Series',
      year: 2011,
      color: 'silver',
      mileage: 12123,
      photos: {
        hero: '//tinyurl.com/y9jxp25b'
      },
      bodyStyle: 'sedan'
    },
    {
      brand: 'kia',
      model: 'Soul!',
      family: 'Soul',
      year: 2011,
      color: 'ignition',
      mileage: 102424,
      bodyStyle: 'suv'
    },

    {brand: 'audi', model: 'A3', family: '3 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'A4', family: '4 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'A5', family: '5 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'A6', family: '6 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'A7', family: '7 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'Q5', family: 'Q5 Series', bodyStyle: 'suv'},
    {brand: 'audi', model: 'Q7', family: 'Q7 Series', bodyStyle: 'suv'},
    {brand: 'audi', model: 'S4', family: 'S4 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'S6', family: 'S6 Series', bodyStyle: 'sedan'},
    {brand: 'audi', model: 'R8', family: 'R8 Series', bodyStyle: 'sedan'},

    {brand: 'bmw', model: '135i', family: '1 Series', bodyStyle: 'coupe'},
    {brand: 'bmw', model: '235i', family: '2 Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: '328i', family: '3 Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: '435i', family: '4 Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: '535i', family: '5 Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: '635i', family: '6 Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: '735i', family: '7 Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: 'x1', family: 'X1 Series', bodyStyle: 'suv'},
    {brand: 'bmw', model: 'i3', family: 'i Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: 'm3', family: 'M Series', bodyStyle: 'sedan'},
    {brand: 'bmw', model: 'm3', family: 'M Series', bodyStyle: 'convertible'},

    {brand: 'dodge', model: 'Challenger', family: 'Challenger', bodyStyle: 'sedan'},
    {brand: 'dodge', model: 'Charger', family: 'Charger', bodyStyle: 'sedan'},
    {brand: 'dodge', model: 'Grand Caravan', family: 'Caravan', bodyStyle: 'mini-van'},
    {brand: 'dodge', model: 'RAM', family: 'RAM', bodyStyle: 'pickup'}

  ]
};
