import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-page-not-found-view',
  templateUrl: './page-not-found-view.component.html',
  styleUrls: ['./page-not-found-view.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PageNotFoundViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
