import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { Car } from '../car-data.service';

@Component({
  selector: 'app-car-search-result',
  templateUrl: './car-search-result.component.html',
  styleUrls: ['./car-search-result.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CarSearchResultComponent implements OnInit {

  @Input() car: Car;

  constructor() { }

  ngOnInit() {}

}
