import {Input, Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Subscription ,  Observable, of } from 'rxjs';
import { distinct, filter, flatMap, map, toArray } from 'rxjs/operators';
import { Car, CarDataService } from '../car-data.service';




import * as _ from 'lodash';

@Component({
  selector: 'app-search-results-view',
  templateUrl: './search-results-view.component.html',
  styleUrls: ['./search-results-view.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SearchResultsViewComponent implements OnInit, OnDestroy {

  results$: Observable<Car[]>;
  years: number[];
  brands: string[];
  models: string[];
  colors: string[];
  conditions: string[];
  transmissions: string[];
  bodyStyles: string[];
  mileageGroups: string[];

  private criteria: string;
  private subscription: Subscription;

  constructor(private carDataService: CarDataService, private route: ActivatedRoute) {}

  private matchesAllSearchTerms(car: Car, terms: string[]) {
    let matchCount = 0;

    _.forEach(terms, (term: string) => {
      if (
        _.includes(_.lowerCase(car.model), _.lowerCase(term)) ||
        _.includes(_.lowerCase(car.brand), _.lowerCase(term))
      ) {
        matchCount++;
      }
    });

    return matchCount === terms.length;
  }

  @Input() set selectedBrand(brand: string) {
    this.carDataService.getModelsForBrand(brand).pipe(toArray()).subscribe(models => this.models = models);
  }

  ngOnInit() {
    this.subscription = this.route.params.subscribe(params => {
      const carSource = this.carDataService.getCars();

      this.results$ = carSource.pipe(
        filter(car => this.matchesAllSearchTerms(car, _.split(params['criteria'], ' '))),
        toArray(),
        map(cars => _.sortBy(cars, ['brand', 'model']))
      );

      carSource
      .pipe(
        distinct(car => car.year),
        map(car => car.year),
        toArray(),
        map(years => _.sortBy(years))
      )
      .subscribe(years => this.years = years);

      carSource
      .pipe(
        distinct(car => car.brand),
        map(car => car.brand),
        toArray(),
        map(values => _.sortBy(values))
      )
      .subscribe(brands => this.brands = brands);

      carSource
      .pipe(
        distinct(car => car.color),
        map(car => car.color),
        toArray(),
        map(values => _.sortBy(values)),
        map(values => _.map(values, _.startCase))
      )
      .subscribe(colors => this.colors = colors);

      carSource
      .pipe(
        distinct(car => car.condition),
        map(car => car.condition),
        toArray(),
        map(values => _.sortBy(values)),
        map(values => _.map(values, _.startCase))
      )
      .subscribe(conditions => this.conditions = conditions);

      carSource
      .pipe(
        distinct(car => car.transmission),
        map(car => car.transmission),
        toArray(),
        map(values => _.sortBy(values)),
        map(values => _.map(values, _.startCase))
      )
      .subscribe(transmissions => this.transmissions = transmissions);

      carSource
      .pipe(
        distinct(car => car.bodyStyle),
        map(car => car.bodyStyle),
        toArray(),
        map(values => _.sortBy(values)),
        map(values => _.map(values, _.startCase))
      )
      .subscribe(bodyStyles => this.bodyStyles = bodyStyles);

      this.mileageGroups = [
        '< 25,000',
        '25,000 - 50,000',
        '50,000 - 60,000',
        '60,000 - 70,000',
        '70,000 - 80,000',
        '90,000 - 100,000',
        '100,000 - 110,000',
        '110,000 - 120,000',
        '120,000 - 130,000',
        '130,000 - 140,000',
        '140,000 - 150,000',
        '150,000 - 160,000',
        '160,000 - 170,000',
        '170,000 - 180,000',
        '180,000 - 190,000',
        '190,000 - 200,000',
        '> 200,000',
      ];

    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
