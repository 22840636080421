import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { from } from 'rxjs';

// Lodash
import * as _ from 'lodash';

// Inventory data
import { INVENTORY } from './car-data';


@Injectable()
export class CarDataService {

  private cars: Car[] = [];
  private idSeed = 2143234;

  private static randomNumber(start: number, end: number): number {
    return Math.floor(Math.random() * (end - start) + start + 1);
  }

  private static randomFrom(options: any[]): any {
    return options[Math.floor(Math.random() * options.length)];
  }

  constructor() {
    // Populate the initial database; this will be different each time the site is loaded
    _.forEach(INVENTORY.cars, (carDescriptor => {

      // Populate with random data
      const collection: string[] = [];
      for (let i = 0; i < CarDataService.randomNumber(6, 15); i++) {
        collection.push('//via.placeholder.com/350x150');
      }
      //
      const photos = {
        hero: _.get(carDescriptor, 'photos.hero', '//via.placeholder.com/350x150'),
        collection: _.get(carDescriptor, 'photos.collection', collection)
      };
      this.cars.push(new Car(
        _.get(carDescriptor, 'brand', ''),
        _.get(carDescriptor, 'family', ''),
        _.get(carDescriptor, 'model', ''),
        _.get(carDescriptor, 'year', Math.floor(Math.random() * 17) + 2000),
        _.get(carDescriptor, 'color', CarDataService.randomFrom(['black', 'green', 'blue', 'white', 'red'])),
        _.get(carDescriptor, 'mileage', Math.floor(Math.random() * 52000)),
        _.get(carDescriptor, 'price', Math.floor(Math.random() * 48000) + .99),
        photos,
        _.get(carDescriptor, 'id', this.idSeed++),
        _.get(carDescriptor, 'condition', CarDataService.randomFrom(['certified pre-owned', 'pre-owned', 'new'])),
        _.get(carDescriptor, 'transmission', CarDataService.randomFrom(['automatic', 'manual'])),
        _.get(carDescriptor, 'bodyStyle', CarDataService.randomFrom([
          'sedan',
          'suv',
          'pickup',
          'mini-van',
          'hatchback',
          'convertible',
          'coupe'
        ]))
      ));
    }));
  }

  public getBrands(): Observable<string> {
    return from(_.chain(this.cars)
      .map((car: Car) => car.brand)
      .uniq()
      .sortBy()
      .value()
    );
  }

  public getModelsForBrand(brand: string): Observable<string> {
    return from(_.chain(this.cars)
      // Needs to be case-insensitive
      .filter((car: Car) => _.lowerCase(brand) === _.lowerCase(car.brand))
      .map((car: Car) => car.model)
      .uniq()
      .value()
    );
  }

  public getCars(): Observable<Car> {
    return from(this.cars);
  }

  public getCar(id: number): Observable<Car> {
    return from([_.find(this.cars, (car: Car) => id === car.id)]);
  }

}

export class Car {

  public display: string;

  constructor(
    public brand: string,
    public family: string,
    public model: string,
    public year: number,
    public color: string,
    public mileage: number,
    public price: number,
    public photos: {
      hero: string,
      collection: string[]
    },
    public id: number,
    public condition: string,
    public transmission: string,
    public bodyStyle: string
  ) {
    this.display = `${this.brand} ${this.model}`;
  }

}
