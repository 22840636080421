import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import { distinct, filter, flatMap, map, toArray } from 'rxjs/operators';

import * as _ from 'lodash';
import { Car, CarDataService } from '../car-data.service';



@Component({
  selector: 'app-inventory-filter',
  templateUrl: './inventory-filter.component.html',
  styleUrls: ['./inventory-filter.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class InventoryFilterComponent implements OnInit {

  typeCtrl: FormControl;
  filteredTypes$: Observable<Car[]>;

  constructor(private carDataService: CarDataService, private router: Router) {
    this.typeCtrl = new FormControl();
    this.filteredTypes$ = this.typeCtrl.valueChanges
      .pipe(
        flatMap(filterCriteria => this.carDataService.getCars().pipe(
          filter(car => _.includes(_.lowerCase(car.brand), _.lowerCase(filterCriteria)) || _.includes(_.lowerCase(car.model), _.lowerCase(filterCriteria))),
          distinct(car => `${car.brand}.${car.model}`),
          toArray(),
          map(cars => _.sortBy(cars, ['brand', 'model']))
        )),
      );
  }

  invokeSearch(criteria: string): void {
    if (!!criteria) {
      this.router.navigate(['/results/', criteria]);
    }
  }

  ngOnInit() {
  }

}
