import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { InventoryFilterComponent } from './inventory-filter/inventory-filter.component';
import { FinanceSelectorComponent } from './finance-selector/finance-selector.component';
import { MediaOpportunityComponent } from './media-opportunity/media-opportunity.component';
import { HeaderComponent } from './header/header.component';
import { SearchHeroComponent } from './search-hero/search-hero.component';
import { NewsFlashComponent } from './news-flash/news-flash.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { ClientListComponent } from './client-list/client-list.component';
import { HardSellHeroComponent } from './hard-sell-hero/hard-sell-hero.component';
import { SearchDetailComponent } from './search-detail/search-detail.component';
import { FooterComponent } from './footer/footer.component';

import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatExpansionModule,
  MatGridListModule,
  MatMenuModule,
  MatInputModule,
  MatSelectModule,
  MatSliderModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BodyStyleSelectorComponent } from './body-style-selector/body-style-selector.component';
import { PriceRangeSelectorComponent } from './price-range-selector/price-range-selector.component';
import { SelectableItemComponent } from './selectable-item/selectable-item.component';
import { LandingViewComponent } from './landing-view/landing-view.component';
import { PageNotFoundViewComponent } from './page-not-found-view/page-not-found-view.component';
import { BrandCardComponent } from './brand-card/brand-card.component';
import { SearchResultsViewComponent } from './search-results-view/search-results-view.component';

import { CarDataService } from './car-data.service';
import { CarSearchService } from './car-search.service';
import { CarSearchResultComponent } from './car-search-result/car-search-result.component';
import { CarDetailViewComponent } from './car-detail-view/car-detail-view.component';
import { KseComponent } from './kse/kse.component';

const appRoutes: Routes = [
  { path: 'landing', component: LandingViewComponent },
  { path: 'results/:criteria', component: SearchResultsViewComponent },
  { path: 'car/:id', component: CarDetailViewComponent },
  { path: '',
    redirectTo: '/landing',
    pathMatch: 'full'
  },
  { path: '**', component: PageNotFoundViewComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    InventoryFilterComponent,
    FinanceSelectorComponent,
    MediaOpportunityComponent,
    HeaderComponent,
    SearchHeroComponent,
    NewsFlashComponent,
    HowItWorksComponent,
    ClientListComponent,
    HardSellHeroComponent,
    SearchDetailComponent,
    FooterComponent,
    BodyStyleSelectorComponent,
    PriceRangeSelectorComponent,
    SelectableItemComponent,
    LandingViewComponent,
    PageNotFoundViewComponent,
    BrandCardComponent,
    SearchResultsViewComponent,
    CarSearchResultComponent,
    CarDetailViewComponent,
    KseComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes
      // ,{ enableTracing: true } // <-- debugging purposes only
    ),
    RouterModule,
    BrowserModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatGridListModule,
    MatMenuModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [CarDataService, CarSearchService],
  bootstrap: [AppComponent]
})
export class AppModule { }
