import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hard-sell-hero',
  templateUrl: './hard-sell-hero.component.html',
  styleUrls: ['./hard-sell-hero.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HardSellHeroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
