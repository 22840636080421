import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Car, CarDataService } from './car-data.service';

import * as _ from 'lodash';


@Injectable()
export class CarSearchService {

  constructor(private carDataService: CarDataService) {

  }

  public findCarsByBrand(partialBrand: string): Observable<Car> {
    return this.carDataService
      .getCars()
      .pipe(filter((car: Car) =>  _.includes(_.lowerCase(car.brand), _.lowerCase(partialBrand))))
      ;
  }

  public findCarsByBrandAndModel(partialBrand: string, partialModel: string): Observable<Car> {
    return this.findCarsByBrand(partialBrand)
      .pipe(filter((car: Car) => _.includes(_.lowerCase(car.model), _.lowerCase(partialModel))))
      ;
  }

}
