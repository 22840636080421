import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-search-hero',
  templateUrl: './search-hero.component.html',
  styleUrls: ['./search-hero.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SearchHeroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
