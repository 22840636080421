import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-selectable-item',
  templateUrl: './selectable-item.component.html',
  styleUrls: ['./selectable-item.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SelectableItemComponent implements OnInit {

  @Input() text: string;
  @Input() cssClass: string;

  public selected = false;

  constructor() { }

  ngOnInit() {
  }

  toggleSelection() {
    this.selected = !this.selected;
  }

}
