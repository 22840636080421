import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { CarDataService } from '../car-data.service';
import { Observable } from 'rxjs';
import { toArray } from 'rxjs/operators';


@Component({
  selector: 'app-brand-card',
  templateUrl: './brand-card.component.html',
  styleUrls: ['./brand-card.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class BrandCardComponent implements OnInit {

  @Input() brand: string;
  public models$: Observable<string[]>;

  constructor(private carDataService: CarDataService) {
  }

  ngOnInit() {
    this.models$ = this.carDataService.getModelsForBrand(this.brand).pipe(toArray());
  }

}
