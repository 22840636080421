import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-finance-selector',
  templateUrl: './finance-selector.component.html',
  styleUrls: ['./finance-selector.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class FinanceSelectorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
