import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-landing-view',
  templateUrl: './landing-view.component.html',
  styleUrls: ['./landing-view.component.less'],
  encapsulation: ViewEncapsulation.Emulated
})
export class LandingViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
